@font-face {
  font-family: "Inter";
  font-weight: 100 900;
  src: url(../fonts/Inter-VariableFont_slnt\,wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSans";
  src:
    url(../fonts/NotoSans-Regular.woff2) format("woff2"),
    url(../fonts/NotoSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  src:
    url(../fonts/NotoSans-Bold.woff2) format("woff2"),
    url(../fonts/NotoSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  font-weight: normal;
  src: url(../fonts/Lato-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Lato";
  font-weight: 700;
  src: url(../fonts/Lato-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansThaiLooped";
  font-weight: normal;
  src: url(../fonts/NotoSansThaiLooped-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansThaiLooped";
  font-weight: 500;
  src: url(../fonts/NotoSansThaiLooped-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansThaiLooped";
  font-weight: bold;
  src: url(../fonts/NotoSansThaiLooped-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansThaiLooped";
  font-weight: 600;
  src: url(../fonts/NotoSansThaiLooped-SemiBold.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansTC";
  font-weight: 100 900;
  src: url(../fonts/NotoSansTC-VariableFont_wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansSC";
  font-weight: 100 900;
  src: url(../fonts/NotoSansSC-VariableFont_wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansJP";
  font-weight: 100 900;
  src: url(../fonts/NotoSansJP-VariableFont_wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansKR";
  font-weight: 100 900;
  src: url(../fonts/NotoSansKR-VariableFont_wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansArabic";
  font-weight: 100 900;
  src: url(../fonts/NotoSansArabic-VariableFont_wdth\,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansHebrew";
  font-weight: 100 900;
  src: url(../fonts/NotoSansHebrew-VariableFont_wdth\,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoNastaliqUrdu";
  font-weight: 100 900;
  src: url(../fonts/NotoNastaliqUrdu-VariableFont_wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansBengali";
  font-weight: 100 900;
  src: url(../fonts/NotoSansBengali-VariableFont_wdth,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansDevanagari";
  font-weight: 100 900;
  src: url(../fonts/NotoSansDevanagari-VariableFont_wdth,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansGujarati";
  font-weight: 100 900;
  src: url(../fonts/NotoSansGujarati-VariableFont_wdth,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansGurmukhi";
  font-weight: 100 900;
  src: url(../fonts/NotoSansGurmukhi-VariableFont_wdth,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansKannada";
  font-weight: 100 900;
  src: url(../fonts/NotoSansKannada-VariableFont_wdth,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansMalayalam";
  font-weight: 100 900;
  src: url(../fonts/NotoSansMalayalam-VariableFont_wdth,wght.woff2)
    format("woff2");
}
@font-face {
  font-family: "NotoSansOriya";
  font-weight: 100 900;
  src: url(../fonts/NotoSansOriya-VariableFont_wdth,wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansTamil";
  font-weight: 100 900;
  src: url(../fonts/NotoSansTamil-VariableFont_wdth,wght.woff2) format("woff2");
}
@font-face {
  font-family: "NotoSansTelugu";
  font-weight: 100 900;
  src: url(../fonts/NotoSansTelugu-VariableFont_wdth,wght.woff2) format("woff2");
}

html,
.locale-region {
  --main-fontFamily: "Inter", sans-serif;
  font-family: var(--main-fontFamily);
  --logo-fontFamily: "Lato", sans-serif;
}

html[data-font-face="Hant"],
.locale-region[data-font-face="Hant"] {
  --main-fontFamily: "NotoSansTC", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Hans"],
.locale-region[data-font-face="Hans"] {
  --main-fontFamily: "NotoSansSC", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Jpan"],
.locale-region[data-font-face="Jpan"] {
  --main-fontFamily: "NotoSansJP", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Kore"],
.locale-region[data-font-face="Kore"] {
  --main-fontFamily: "NotoSansKR", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Thai"],
.locale-region[data-font-face="Thai"] {
  --main-fontFamily: "NotoSansThaiLooped", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Beng"],
.locale-region[data-font-face="Beng"] {
  --main-fontFamily: "NotoSansBengali", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Deva"],
.locale-region[data-font-face="Deva"] {
  --main-fontFamily: "NotoSansDevanagari", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Gujr"],
.locale-region[data-font-face="Gujr"] {
  --main-fontFamily: "NotoSansGujarati", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Guru"],
.locale-region[data-font-face="Guru"] {
  --main-fontFamily: "NotoSansGurmukhi", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Knda"],
.locale-region[data-font-face="Knda"] {
  --main-fontFamily: "NotoSansKannada", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Mlym"],
.locale-region[data-font-face="Mlym"] {
  --main-fontFamily: "NotoSansMalayalam", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Orya"],
.locale-region[data-font-face="Orya"] {
  --main-fontFamily: "NotoSansOriya", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Taml"],
.locale-region[data-font-face="Taml"] {
  --main-fontFamily: "NotoSansTamil", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Telu"],
.locale-region[data-font-face="Telu"] {
  --main-fontFamily: "NotoSansTelugu", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}

html[dir="rtl"],
.locale-region[dir="rtl"] {
  --main-fontFamily: "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Arab"],
.locale-region[data-font-face="Arab"] {
  --main-fontFamily: "NotoSansArabic", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Hebr"],
.locale-region[data-font-face="Hebr"] {
  --main-fontFamily: "NotoSansHebrew", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}
html[data-font-face="Aran"],
.locale-region[data-font-face="Aran"] {
  --main-fontFamily: "NotoNastaliqUrdu", "NotoSans", sans-serif;
  --logo-fontFamily: var(--main-fontFamily);
}

#modal-root {
  z-index: 1000;
}
html,
body,
#root,
#root > * {
  font-family: var(--main-fontFamily);
  height: 100%;
  margin: 0;
  padding: 0;
}
/* tanstack query dev tools */
.tsqd-parent-container {
  height: auto !important;
}
button,
input,
textarea {
  font-family: var(--main-fontFamily);
}
p {
  margin: 0;
  padding: 0;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
